import React from 'react'

import PageWithLeftMenu from '../../components/PageWithLeftMenu'
import P from '../../components/P'
import SEO from '../../components/seo'

import items from './_items'

export default () => {
  return (
    <PageWithLeftMenu title="B.E.B.E" items={items}>
      <SEO
        title="BEBE - sistem cultul creștin după Evanghelie"
        keywords={[`bebe`, 'creștin după evanghelie', `fundația ebe`]}
      />
      <P style={{ textAlign: 'center' }}>
        <P>Sistemul de Educație Biblică prin Extensie</P>
        <P>Coordonator sistem B.E.B.E.</P>
        <P>
          <i>Petrică Ursache</i>
        </P>
      </P>

      <P>
        <b>Viziunea B.E.B.E.</b>
      </P>

      <P>
        O rețea de încredere de lideri slujitori ucenicizatori, la nivel
        național – bărbați și femei – cu scopul de a se multiplica, iar treptat
        sub călăuzirea Duhului Sfânt, bisericile să devină relevante și
        multiplicatoare.
      </P>

      <P>
        <b>BEBE</b> echipează lideri-slujitori – bărbați și femei – în special
        din cadrul Cultului Creștin după Evanghelie. Coordonatorul național al
        sistemului BEBE este Ursache Petru, asistat de fratele Dăneti Ioan,
        pentru partea de vest a țării. Coordonatorul național este unul din
        presbiterii Bisericii Creștine după Evanghelie „Filadelfia” din Botoșani
      </P>
      <P>
        <b>Nevoia</b>
      </P>
      <P ml={4}>
        <li>
          Existența unui număr mic de lideri-slujitori formați prin sistemele de
          pregătire formală, în raport cu cerințele bisericilor locale;
        </li>
        <li>
          Lipsa unei specializări a liderilor-slujitori în conducere și
          administrație bisericească;
        </li>
        <li>
          Lipsa unei mișcări de multiplicare a liderilor ucenicizatori la nivel
          național;
        </li>
        <li>
          Insuficienta implicare a surorilor în lucrarea de ucenicizare și de
          multiplicare a lucrării femeilor din bisericile locale ale Cultului
          (conform Tit 2:3);
        </li>
        <b>
          Existența unui număr foarte mic de lideri-slujitori echipați, în unele
          biserici românești din Diaspora.
        </b>
      </P>

      <P>
        <b>Soluția</b>
      </P>
      <P ml={4}>
        <li>Echiparea de lideri ucenicizatori conform profilului stabilit;</li>
        <li>
          Pregătire în domeniul conducerii și administrației bisericești prin
          programul de Teologie Pastorală;
        </li>
        <li>
          Formarea unei echipe de lideri multiplicatori, cu accent pe educația
          adulților;
        </li>
        <li>Echiparea unui grup de femei capabile să se multiplice;</li>
        <b>
          Echiparea de lideri ucenicizatori în bisericile românești din
          Diaspora.
        </b>
      </P>
    </PageWithLeftMenu>
  )
}
