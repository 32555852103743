const items = [
  {
    link: '/sisteme',
    text: 'Sisteme',
  },
  {
    link: '/sisteme/lucrarea-cu-femeile',
    text: 'Lucrarea cu femeile',
  },
  {
    link: '/sisteme/s-e-b-e',
    text: 'S.E.B.E',
  },
  {
    link: '/sisteme/r-e-b-e',
    text: 'R.E.B.E',
  },
  {
    link: '/sisteme/b-e-b-e',
    text: 'B.E.B.E',
  },
  {
    link: '/sisteme/b-a-c-i',
    text: 'B.A.C.I',
  },
]
export default items
